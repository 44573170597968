import React from "react";

import ProductListItem from "../elements/ProductListItem";

const ProductsList = ({ products }) => {
  const productList = products.edges.map((product) => (
    <ProductListItem product={product} key={product.node.url} />
  ));

  return <section className="products-list">{productList}</section>;
};

export default ProductsList;
